var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "charg-detail-component" }, [
    _vm.dataType == "sendData"
      ? _c("div", [
          _c("div", { staticClass: "detail-name" }, [_vm._v("收信人")]),
          _c(
            "div",
            { staticClass: "set-input" },
            _vm._l(_vm.dataset, function (item, index) {
              return _c("div", { key: index, staticClass: "set-list" }, [
                _c("span", [_vm._v(" " + _vm._s(item.buyerMobilePhone))]),
              ])
            }),
            0
          ),
          _c("div", { staticClass: "detail-name list-margin" }, [
            _vm._v("短信内容"),
          ]),
          _c("div", { staticClass: "message" }, [
            _c("div", [
              _vm.dataset.length > 1
                ? _c("div", [
                    _vm._v(
                      "\n          【深圳动漫园】感谢您于{日期+时间}\n          使用深圳动漫园小程序申请开具发票。很抱歉因系统临时\n          维护未开票成功。目前开票功能已经恢复，您可再次打开小程序申请开票\n          {URL Link}\n        "
                    ),
                  ])
                : _c(
                    "div",
                    _vm._l(_vm.dataset, function (item, index) {
                      return _c("div", { key: index }, [
                        _vm._v(
                          "\n            【深圳动漫园】感谢您于" +
                            _vm._s(item.invoiceDate) +
                            "\n            使用深圳动漫园小程序申请开具发票。很抱歉因系统临时\n            维护未开票成功。目前开票功能已经恢复，您可再次打开小程序申请开票\n            {URL Link}\n          "
                        ),
                      ])
                    }),
                    0
                  ),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "detail-btn", attrs: { align: "right" } },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.$emit("close", false)
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.sendmMsg()
                    },
                  },
                },
                [_vm._v("发送")]
              ),
            ],
            1
          ),
        ])
      : _vm._e(),
    _vm.dataType == "keepData"
      ? _c("div", [
          _c("div", { staticClass: "keep-list" }, [
            _c("div", { staticClass: "keep-title" }, [_vm._v("申请时间")]),
            _c("div", { staticClass: "keep-explan" }, [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm._f("dateFormat")(
                      _vm.dataset[0].createTime,
                      "YYYY-MM-DD HH:mm:ss"
                    )
                  ) +
                  "\n      "
              ),
            ]),
          ]),
          _c("div", { staticClass: "keep-list" }, [
            _c("div", { staticClass: "keep-title" }, [_vm._v("开票记录单号")]),
            _c("div", { staticClass: "keep-explan" }, [
              _vm._v("\n        " + _vm._s(_vm.dataset[0].billNo) + "\n      "),
            ]),
          ]),
          _c("div", { staticClass: "keep-list" }, [
            _c("div", { staticClass: "keep-title" }, [_vm._v("开票金额")]),
            _c("div", { staticClass: "keep-explan" }, [
              _vm._v(
                "\n        " + _vm._s(_vm.dataset[0].totalAmount) + "\n      "
              ),
            ]),
          ]),
          _c("div", { staticClass: "keep-list" }, [
            _c("div", { staticClass: "keep-title" }, [_vm._v("抬头类型")]),
            _c("div", { staticClass: "keep-explan" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.dataset[0].openInvoiceType) +
                  "\n      "
              ),
            ]),
          ]),
          _c("div", { staticClass: "keep-list" }, [
            _c("div", { staticClass: "keep-title" }, [_vm._v("发票抬头")]),
            _c("div", { staticClass: "keep-explan" }, [
              _vm._v(
                "\n        " + _vm._s(_vm.dataset[0].buyerName) + "\n      "
              ),
            ]),
          ]),
          _c("div", { staticClass: "keep-list" }, [
            _c("div", { staticClass: "keep-title" }, [_vm._v("手机号码")]),
            _c("div", { staticClass: "keep-explan" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.dataset[0].buyerMobilePhone) +
                  "\n      "
              ),
            ]),
          ]),
          _vm.dataset[0].sendMessage == "未发送短信提醒"
            ? _c("div", { staticClass: "keep-list" }, [
                _c("div", { staticClass: "keep-title" }, [_vm._v("提醒状态")]),
                _c("div", { staticClass: "keep-explan" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.dataset[0].sendMessage) +
                      "\n      "
                  ),
                ]),
              ])
            : _vm._e(),
          _vm.dataset[0].sendMessage == "已发送短信提醒"
            ? _c("div", { staticClass: "keep-list" }, [
                _c("div", { staticClass: "keep-title" }, [_vm._v("短信内容")]),
                _c("div", { staticClass: "keep-explan" }, [
                  _vm._v(
                    "\n        【深圳动漫园】感谢您于\n        " +
                      _vm._s(_vm.dataset[0].invoiceDate) +
                      "\n        使用深圳动漫园小程序申请开具发票。很抱歉因系统\n        临时维护未开票成功。目前开票功能已经恢复，您可再次打开小程序申请开票\n        {URL Link}\n      "
                  ),
                ]),
              ])
            : _vm._e(),
          _vm.dataset[0].description
            ? _c("div", { staticClass: "keep-list" }, [
                _c("div", { staticClass: "keep-title" }, [_vm._v("失败原因")]),
                _c("div", { staticClass: "keep-explan" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.dataset[0].description) +
                      "\n      "
                  ),
                ]),
              ])
            : _vm._e(),
          _c(
            "div",
            { staticClass: "detail-btn", attrs: { align: "right" } },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.$emit("close", false)
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }